<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.66373 7.4344H6.00791C6.40613 7.4344 6.73194 7.10859 6.73194 6.71037V0.918123C6.73194 0.519906 6.40613 0.194092 6.00791 0.194092H1.66373C1.26551 0.194092 0.939697 0.519906 0.939697 0.918123V6.71037C0.939697 7.10859 1.26551 7.4344 1.66373 7.4344ZM1.66373 13.2266H6.00791C6.40613 13.2266 6.73194 12.9008 6.73194 12.5026V9.60649C6.73194 9.20827 6.40613 8.88246 6.00791 8.88246H1.66373C1.26551 8.88246 0.939697 9.20827 0.939697 9.60649V12.5026C0.939697 12.9008 1.26551 13.2266 1.66373 13.2266ZM8.90404 13.2266H13.2482C13.6464 13.2266 13.9722 12.9008 13.9722 12.5026V6.71037C13.9722 6.31215 13.6464 5.98634 13.2482 5.98634H8.90404C8.50582 5.98634 8.18 6.31215 8.18 6.71037V12.5026C8.18 12.9008 8.50582 13.2266 8.90404 13.2266ZM8.18 0.918123V3.81425C8.18 4.21246 8.50582 4.53828 8.90404 4.53828H13.2482C13.6464 4.53828 13.9722 4.21246 13.9722 3.81425V0.918123C13.9722 0.519906 13.6464 0.194092 13.2482 0.194092H8.90404C8.50582 0.194092 8.18 0.519906 8.18 0.918123Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDashboard'
}
</script>
